<template>
  <div class="region-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6" >
        <b-form-group id="input-group-title" :label="`سەھىپە نامى`" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`سەھىپە نامىنى كىرگۈزۈڭ `" />
          <b-form-invalid-feedback id="title-live-feedback">
            سەھىپە نامىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
          <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" placeholder=" تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import flagMixin from "@m@/flag.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "FlagForm",
    mixins: [ validationMixin, validateStateMixin, flagMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
    },
    validations(){
      let validates = {
        title: { required },
      };
      return {
        formData: validates
      }
    },
    computed: {},
    created() {
      if (this.action === 'edit'){
        this.getFlag(this.modelId)
          .then(({ data: navigation })=>{
            this.formData = navigation;
          })
      }
    },
    mounted() {},
    data(){
      return {
        formData: {
          title: '',
          sort: '',
          is_show: true
        },
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          this.storeFlag(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateFlag(this.formData);
        }
      }
    }
  }
</script>
